import React from "react"
import { navigate } from "gatsby"

const Complete = () => {
    React.useEffect(() => {
        navigate('/mypage')
    }, []);

    return (<>callback</>)
}

export default Complete;